::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 4px; /* 滑块圆角 */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 滚动条轨道背景色 */
  border-radius: 4px; /* 轨道圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 滑块悬停时颜色 */
}
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
    display: flex;
    justify-content: flex-start;
  }
.justify-center {
    display: flex;
    justify-content: center;
}
  
.justify-end {
    display: flex;
    justify-content: flex-end;
}
.justify-evenly {
    display: flex;
    justify-content: space-evenly;
}
.justify-around {
    display: flex;
    justify-content: space-around;
}
.justify-between {
    display: flex;
    justify-content: space-between;
}
.align-start {
    display: flex;
    align-items: flex-start;
}
.align-center {
    display: flex;
    align-items: center;
}
.align-end {
    display: flex;
    align-items: flex-end;
}

.rc-dialog-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .rc-dialog-body {
    padding: 50px 32px 32px 32px;
  }
  .rc-dialog-close {
    opacity: 1;
    right: 17px;
    top: 17px;
  }
}