$primary_sub_color: rgba(255,255,255, 0.7);
.containerWrap {
  background-color: #1A1A1A;
  color: #fff;
  background-image: url(~@/img/bg.png);
  background-size: contain;
  background-position: center 100px;
  background-repeat: no-repeat;
}
.container {
  .headerWrap {
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    backdrop-filter: blur(3px);
  }
  .header {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px 0;
    font-size: 26px;
    line-height: 22px;
    & > img {
      margin-right: 8px;
      height: 40px;
    }
    .navs {
      margin-left: auto;
      font-size: 18px;
      color: $primary_sub_color;
      & > * {
        margin-left: 62px;
        cursor: pointer;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
  .introduce {
    max-width: 1200px;
    margin: 180px auto 0 auto;
    .title {
      font-size: 68px;
      font-weight: 800;
    }
    .subTitle {
      margin-top: 8px;
      font-size: 24px;
      color: $primary_sub_color;
    }
    .list {
      margin-top: 40px;
      .item {
        margin-right: 60px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
      .circleDot {
        width: 12px;
        height: 12px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-right: 16px;
        margin-top: 15px;
      }
      .itemTitle {
        font-size: 32px;
      }
      .itemSubTitle {
        font-size: 20px;
        line-height: 36px;
        margin-top: 8px;
        color: $primary_sub_color;
      }
    }
    .btn {
      width: 210px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background-color: #EC1F28;
      margin-top: 80px;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background-color: #EC4E55;
      }
    }
    .boxList {
      margin-top: 221px;
      width: 100%;
      .box {
        flex: 1;
        height: 320px;
        padding: 0 32px;
        box-sizing: border-box;
        background-color: #262525;
        box-shadow: 0px 2px 43px 6px rgba(79,0,0,0.1);
        position: relative;
        margin-right: 48px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 12px;
          width: 100%;
          background-color: #9C9C9C;
          
        }
        .summary {
          margin-top: 48px;
          font-weight: 600;
          font-size: 20px;
          img {
            margin-right: 22px;
            width: 80px;
          }
        }
        .des {
          font-size: 18px;
          line-height: 30px;
          color: $primary_sub_color;
          margin-top: 54px;
        }
      }
    }
  }
  .tabs {
    margin-top: 80px;
    padding-top: 80px;
    background-color: #232323;
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 820px;
    box-sizing: border-box;
    .tabList {
      margin: 0 auto;
      max-width: 1200px;
      .tab {
        font-size: 28px;
        color: $primary_sub_color;
        position: relative;
        cursor: pointer;
        flex: 1;
        text-align: center;
        &.active {
          color: #fff;
          background: linear-gradient( 180deg, #EC1F28 0%, rgba(236,31,40,0.4) 100%);
          border-radius: 2px;
          padding: 18px 0 24px 0;
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 100%;
            height: 2px;
            background-color: #EC1F28;
          }
        }
      }
    }
    .tabContent {
      max-width: 1200px;
      margin: auto;
    }
  }
  .advantage {
    padding: 80px 0;
    max-width: 1200px;
    margin: auto;
    .title {
      font-size: 36px;
      text-align: center;
    }
    .subTitle {
      font-size: 22px;
      margin-top: 8px;
      color: $primary_sub_color;
      text-align: center;
    }
    .list {
      margin-top: 40px;
      display: flex;
      .listItem {
        flex: 1;
        box-sizing: border-box;
        background-color: #262525;
        padding-bottom: 40px;
        margin-right: 24px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        img {
          width: 100%;
        }
        .pdleft32 {
          padding-left: 32px;
          padding-right: 32px;
          margin-top: 32px;
        }
        .label {
          font-size: 20px;
        }
        .splitLine {
          margin-top: 17px;
          width: 46px;
          height: 2px;
          background-color: $primary_sub_color;
        }
        .des {
          margin-top: 23px;
          font-size: 16px;
          color: $primary_sub_color;
          line-height: 25px;
        }
      }
    }
  }
  .contact {
    padding: 80px 0;
    margin: auto;
    background-color: #232323;
    .title {
      font-size: 36px;
      text-align: center;
    }
    .subTitle {
      font-size: 22px;
      margin-top: 8px;
      color: $primary_sub_color;
      text-align: center;
    }
    .formWrap {
      width: 800px;
      height: 446px;
      margin: 29px auto 0 auto;
      position: relative;
    }
    .form {
      box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 56px 72px 0 72px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      background-color: #232323;
      border-radius: 8px;
      .inputWrap {
        width: 100%;
        .label {
          font-size: 18px;
          margin-bottom: 16px;
        }
        .input {
          width: 100%;
          background-color: transparent;
          border: 1px solid #B3B3B3;
          line-height: 44px;
          border-radius: 4px;
          color: #fff;
          padding: 0 20px;
          font-size: 16px;
        }
        input {
          height: 44px;
        }
        textarea {
          height: 162px;
        }
      }
    }
    .formTop {
      width: 166px;
      height: 166px;
      background: linear-gradient( 136deg, #EC1F28 0%, rgba(236,31,40,0) 100%);
      position: absolute;
      top: -60px;
      left: -60px;
      border-radius: 50%;
      z-index: 0;
    }
    .formBottom {
      width: 473px;
      height: 455px;
      background-image: url(~@/img/form-bg.png);
      position: absolute;
      right: -53px;
      bottom: -57px;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .submit {
      margin: 32px auto 0 auto;
      width: 196px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      background-color: #EC1F28;
      border-radius: 8px;
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .footer {
    max-width: 1200px;
    margin: auto;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      height: 52px;
    }
    .copyRight {
      font-size: 18px;
      & > div {
        margin-top: 16px;
        text-align: right;
        color: $primary_sub_color;
      }
    }
  }
}

.formItemError {
  color: #EC1F28;
  font-size: 14px;
  padding-top: 10px;
  height: 36px;
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .successIcon {
    width: 48px;
    height: 48px;
  }
  .successText {
    font-size: 24px;
    color: #1A1A1A;
    margin-top: 18px;
  }
  .successDes {
    font-size: 18px;
    color: #808080;
    margin-top: 12px;
  }
  .successBtn {
    margin-top: 40px;
    width: 180px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #fff;
    font-size: 18px;
    border-radius: 8px;
    background-color: #EC1F28;
    cursor: pointer;
  }
}
.closeIcon {
  width: 20px;
  height: 20px;
}