$primary_sub_color: rgba(255,255,255, 0.7);
.cooperationTab {
  margin-top: 103px;
  width: 681px;
  .h1 {
    font-size: 32px;
    font-weight: 600;
  }
  .todoList {
    margin-top: 50px;
    font-size: 18px;
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgba(219, 229, 255, 0), rgba(219, 229, 255, 0.6), rgba(219, 229, 255, 0)) 1 1;
    padding-bottom: 50px;
    padding-left: 36px;
    & > div {
      margin-bottom: 12px;
      position: relative;
      font-size: 16px;
      color: $primary_sub_color;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      &::after {
        content: '';
        position: absolute;
        left: -36px;
        top: 2px;
        width: 18px;
        height: 18px;
        background-image: url('~@/img/checked.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .des {
    margin-top: 47px;
    font-size: 16px;
    line-height: 30px;
    color: $primary_sub_color;
  }
}