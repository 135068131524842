$primary_sub_color: rgba(255,255,255, 0.7);
.consultTab {
  margin-top: 103px;
  width: 681px;
  .h1 {
    font-size: 32px;
    font-weight: 600;
    &.pdf {
      margin-top: 49px;
    }
  }
  .todoList {
    margin-top: 24px;
    font-size: 18px;
    padding-left: 36px;
    & > div {
      margin-bottom: 12px;
      position: relative;
      font-size: 16px;
      color: $primary_sub_color;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      &::after {
        content: '';
        position: absolute;
        left: -36px;
        top: 2px;
        width: 18px;
        height: 18px;
        background-image: url('~@/img/checked.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .demo {
    margin-top: 24px;
    display: flex;
    height: 110px;
    box-sizing: border-box;
    padding-bottom: 50px;
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgba(219, 229, 255, 0), rgba(219, 229, 255, 0.6), rgba(219, 229, 255, 0)) 1 1;
    .demoLeft {
      background-color: #EC1F28;
      font-size: 18px;
      border-radius: 4px 0 0 4px;
      line-height: 60px;
      width: 172px;
      text-align: center;
    }
    .demoRight {
      background-color: #fff;
      padding: 0 19px;
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        
        margin: auto;
      }
    }
  }
}